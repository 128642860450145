.AC-Loader__Wrap {
  
  &.AC-Loader--Centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  
}
  
