// @keyframes highlight {
//   0% {
//     // background-color: rgb(229, 239, 253);
//     background-color: rgba(0, 128, 96, .5);
//   }
//   100% {
//     background-color: none;
//   }
// }

.Polaris-ResourceItem__Container.AC-ListItem__Active {
  background-color: rgb(242, 247, 254);
  &:hover {
    background-color: rgb(229, 239, 253);
  }
}

// .Polaris-ResourceItem__Container.AC-ListItem__Highlight {
//   animation: highlight 0.5s;
//   animation-timing-function: ease-out;
// }

// .AC-ResourceList__Plain {
//   .Polaris-ResourceItem {
//     &:hover {
//       background-color: inherit;
//       cursor: default
//     }
//   }
// }

.AC-Getting_Started--Large-Icons {
  .Polaris-Stack__Item {
    >.Polaris-Icon {
      height: 5rem;
      width: 5rem;
    }

  }
}