.AC-Plan__Price {
  font-size: 50px;
  font-weight: normal;
  margin: 0 0.8rem 1.6rem;
  line-height: initial;
}

.AC-Feature-Card__Active {
  .Polaris-Card {
    border: 2px solid var(--p-action-primary);
  }
}


.AC-Feature-Item {
  position: relative;
  &.Active {
    font-weight: 600;
    color: var(--p-action-primary); 
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) 3;
    -moz-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) 3;
    -webkit-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) 3;
    -o-animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) 3;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) 3;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    // .AC-Feature-Item__Bouncing-Icon {
    //   display: block;
    //   -moz-animation: bounce 2s 2 ;
    //   -webkit-animation: bounce 2s 2 ;
    //   -o-animation: bounce 2s 2 ;
    //   animation: bounce 2s 2 ;
    //   position: absolute;
    //   left: -42px;
    // }
    // .Polaris-Icon {
      // -moz-animation: hideDelay 0s ease-in 6s forwards;
      // -webkit-animation: hideDelay 0s ease-in 6s forwards;
      // -o-animation: hideDelay 0s ease-in 6s forwards;
      // animation: hideDelay 0s ease-in 6s forwards;
      // -webkit-animation-fill-mode: forwards;
    //   animation-fill-mode: forwards;
    // }
  }
} 
 
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-25px);
  }
  60% {
    transform: translateX(-10px);
  }
}

@keyframes hideDelay {
  to {
      width:0;
      height:0;
      overflow:hidden;
  }
}
@-webkit-keyframes hideDelay {
  to {
      width:0;
      height:0;
      visibility:hidden;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}